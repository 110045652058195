import ENV from 'environments'
import { tokenService } from '../Token'
import { getFormData, getApiUrl, fetchBlob, handleError } from './utils'

class Api {
  isRefreshingToken = false
  waitRequests = []
  hosts = ENV.api

  init ({ logout }) {
    this._logout = logout
  }

  apiRequest (apiAction, meta = {}) {
    if (meta.noAuth) {
      return this._request(apiAction, meta)
    }

    if (!tokenService.getAccessToken()) {
      this._logout()
      return Promise.reject(new Error('Unauthorized'))
    }

    if (!tokenService.isTokenExpired(tokenService.getAccessToken())) {
      return this._request(
        {
          ...apiAction, headers: { ...apiAction.headers, Authorization: `Bearer ${tokenService.getAccessToken()}` },
        },
        meta,
      )
    }

    if (this.isRefreshingToken) {
      return new Promise((resolve, reject) => {
        this.waitRequests.push({ resolve, reject })
      }).then(() => this._request(
        {
          ...apiAction, headers: { ...apiAction.headers, Authorization: `Bearer ${tokenService.getAccessToken()}` },
        },
        meta,
      ))
    }

    this.isRefreshingToken = true
    this._request({
      hostname: this.hosts.auth,
      url: '/connect/token',
      data: getFormData({
        client_id: 'cdru.web.admin',
        // client_secret: 'secret',
        grant_type: 'refresh_token',
        refresh_token: tokenService.getRefreshToken(),
      }),
      headers: {},
      method: 'POST',
    }, {
      noAuth: true,
      sendFormData: true,
    })
      .then(({ result }) => {
        this.isRefreshingToken = false
        tokenService.setTokenData(result)
        this.waitRequests.forEach(({ resolve }) => {
          resolve()
        })
        this.waitRequests = []
      })
      .catch(error => {
        this.isRefreshingToken = false
        this.waitRequests.forEach(({ reject }) => {
          reject(error)
        })
        this.waitRequests = []
        this._logout()
      })

    return new Promise((resolve, reject) => {
      this.waitRequests.push({ resolve, reject })
    }).then(() => this._request(
      {
        ...apiAction, headers: { ...apiAction.headers, Authorization: `Bearer ${tokenService.getAccessToken()}` },
      },
      meta,
    ))
  }

  _request (apiAction, meta) {
    const {
      method,
      url, // NOTE: must have a leading slash
      query,
      data,
      headers,
      hostname,
    } = apiAction

    const requestOptions = {
      method,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        ...headers,
      },
    }

    const methodUpperCase = method.toUpperCase()
    if (data && (methodUpperCase === 'POST' || methodUpperCase === 'PUT' || methodUpperCase === 'PATCH')) {
      if (meta && meta.sendFormData) {
        requestOptions.body = data
      } else {
        requestOptions.headers['Content-Type'] = 'application/json'
        requestOptions.body = typeof data !== 'string' ? JSON.stringify(data) : data
      }
    }

    const requestUrl = getApiUrl({ pathname: url, query, hostname })

    if (meta && meta.getBlob) {
      return fetchBlob(requestUrl, requestOptions)
    }

    return fetch(requestUrl, requestOptions)
      .then(response => {
        const { status, statusText, ok, isMock } = response

        let bodyPromise

        if (status === 204) {
          // note: no content status
          bodyPromise = Promise.resolve(null)
        } else if (
          response.headers.get('Content-Length') !== '0' &&
          response.headers.get('Content-Type').includes('application/json')
        ) {
          bodyPromise = response.json()
        } else {
          bodyPromise = Promise.resolve({})
        }

        return bodyPromise.then(body => ({ status, statusText, ok, body, isMock }))
      })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            this._logout()
          }

          return handleError(response)
        }

        return response
      })
      .then(response => {
        const { status, body } = response

        return { result: body, status }
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  }
}

export const apiService = new Api()
