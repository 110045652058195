import _get from 'lodash/get'
import { tokenService } from 'services/Token'

const MODULE_NAME = 'token'
export const SET_USER = `${MODULE_NAME}/SET_USER`
export const CLEAN_USER = `${MODULE_NAME}/CLEAN_USER`

export const updateToken = result => dispatch => {
  tokenService.setTokenData(result)

  const tokenPayload = tokenService.getTokenPayload(tokenService.getAccessToken())
  const userRole = _get(tokenPayload, 'role')
  const firstName = _get(tokenPayload, 'first_name', '')
  const lastName = _get(tokenPayload, 'last_name', '')
  const companyName = _get(tokenPayload, 'company_name', '')
  dispatch({
    type: SET_USER,
    payload: { role: userRole, firstName, lastName, companyName },
  })
}

export const logout = () => dispatch => {
  tokenService.setTokenData({})
  dispatch({
    type: CLEAN_USER,
  })
}
