import { getLang } from './localizationSelectors'

const MODULE_NAME = 'localization'

export const SET_TEXTS = `${MODULE_NAME}/SET_TEXTS`

export const onLoadTexts = () => (dispatch, getState) => {
  const lang = getLang(getState())

  return import(`locales/${lang}.json`)
    .then(module => {
      dispatch({
        type: SET_TEXTS,
        payload: module.default,
      })
    })
    .catch(() => {
      console.warn(`No texts for ${lang}`)
    })
}
