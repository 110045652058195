import AdminPortalApi from './AdminPortalApi'
import UserPortalApi from './UserPortalApi'
import AuthApi from './AuthApi'
import { apiService } from './Api'

export const adminPortalApiService = new AdminPortalApi()
export const userPortalApiService = new UserPortalApi()
export const authApiService = new AuthApi()

export default apiService
