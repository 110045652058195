import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import CoreLayout from 'apps/CoreLayout'
import { ReduxContext } from 'utils/module'
import store from './store'
import './App.css'

const App = () => (
  <Provider store={store} context={ReduxContext}>
    <Router>
      <CoreLayout />
    </Router>
  </Provider>
)

export default App
