export const ROUTES = {
  home: {
    root: '/',
  },
  endUser: {
    root: '/',
    auth: {
      root: '/',
      login: '/login',
    },
    userPortal: {
      root: '/',
      avatarSelection: '/avatars-list',
      intro: {
        mainIntro: '/intro',
        moduleIntro: '/module-intro',
      },
      debrief: {
        list: '/debrief-list',
        actionList: '/action-module-list',
        module: '/debrief-module',
      },
    },
    notFound: '/404',
  },
  admin: {
    root: '/',
    adminPortal: {
      moduleScripts: {
        root: '/scripts',
        module: '/scripts/:moduleId',
        scale: '/scripts/:moduleId/:scaleId',
        subScale: '/scripts/:moduleId/:scaleId/:subScaleId',
      },
      avatars: '/avatars',
      users: '/users',
      intro: {
        root: '/welcome-page',
        mainIntroduction: '/welcome-page/main-introduction',
        moduleIntroduction: '/welcome-page/module-introduction',
      },
      dataReport: {
        root: '/data-report',
        tooltip: '/data-report/tooltip',
      },
    },
    logout: '/logout',
    notFound: '/404',
  },
}
