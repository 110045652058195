import React, { useState, useEffect, useContext } from 'react'
import uuid from 'uuid'

export const CurrentModuleIdContext = React.createContext('')

export const ReduxContext = React.createContext()

const useModuleIdContext = moduleId => {
  const [id, setModuleId] = useState(moduleId)
  return [
    id,
    id => {
      setModuleId(id)
    },
  ]
}

export const createModule = ({
  component,
  reducer = () => ({}),
  name,
  isUnique,
  context = CurrentModuleIdContext,
}) => props => {
  const { store } = useContext(ReduxContext)
  const [currentModuleId, setCurrentModuleId] = useState('')
  const [moduleId, setModuleId] = useModuleIdContext(useContext(context))
  useEffect(() => {
    if (!moduleId || !currentModuleId) {
      const moduleId = isUnique ? uuid() : `${name}Module`
      setCurrentModuleId(moduleId)
      setModuleId(moduleId)
      store.injectReducer({
        [moduleId]: reducer(moduleId),
      })
    }
    return () => {
      store.excludeReducer(currentModuleId)
    }
  }, [currentModuleId])

  const factory = React.createFactory(component)

  return (
    <CurrentModuleIdContext.Provider value={currentModuleId}>
      <context.Provider value={moduleId}>
        {factory(props)}
      </context.Provider>
    </CurrentModuleIdContext.Provider>
  )
}
