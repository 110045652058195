export default class Token {
  getExpirationDate (token) {
    const payload = this.getTokenPayload(token)
    const date = new Date(0)

    date.setUTCSeconds(payload.exp)

    return date
  }

  getTokenData () {
    return JSON.parse(localStorage.getItem('tokenData')) || {}
  }

  getAccessToken () {
    return this.getTokenData().access_token
  }

  getRefreshToken () {
    return this.getTokenData().refresh_token
  }

  setTokenData (tokenData) {
    localStorage.setItem('tokenData', JSON.stringify(tokenData))
  }

  getTokenPayload (token) {
    if (token && token.split('.').length === 3) {
      try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        return JSON.parse(
          decodeURIComponent(encodeURIComponent(window.atob(base64))),
        )
      } catch (e) {
        return {}
      }
    }
  }

  getUserRole () {
    const { role } = this.getTokenPayload(this.getAccessToken()) || {}
    return role
  }

  isTokenExpired (token = '') {
    if (!token) {
      return true
    }
    const expirationDate = this.getExpirationDate(token)
    return expirationDate.valueOf() <= new Date().valueOf()
  }
}
