import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import apiService from 'services/Api'
import { ROUTES } from 'constants/routes'
import { connect } from 'hocs/connect'
import { logout } from 'store/modules/auth/authActions'
import { onLoadTexts } from 'store/modules/localization/localizationActions'
import { getUserRole } from 'store/modules/auth/authSelectors'
import ENV from 'environments'
import CoreLayout from './CoreLayout'

export default compose(
  connect(
    state => ({
      userRole: getUserRole(state),
    }),
    {
      onLoadTexts,
      logout,
    }
  ),
  withRouter,
  lifecycle({
    componentWillMount () {
      apiService.init({
        logout: () => {
          this.props.history.replace(ROUTES.endUser.auth.root)
          this.props.logout()
        },
      })
    },
    componentDidMount () {
      console.log(`%cFrontend version: ${ENV.version} `, 'font-size: 16px;')
      this.props.onLoadTexts()
    },
  }),
)(CoreLayout)
