import { combineReducers } from 'redux'
import authReducer from './auth/authReducer'
import localizationReducer from './localization/localizationReducer'

const makeRootReducer = asyncReducers => {
  return combineReducers({
    auth: authReducer,
    localization: localizationReducer,
    ...asyncReducers,
  })
}

export default makeRootReducer
