import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { USER_ROLES } from 'constants/auth'

const EndUserModule = React.lazy(() => import('modules/EndUser'))
const AdminModule = React.lazy(() => import('modules/Admin'))

const CoreLayout = ({ userRole }) => (
  <React.Fragment>
    <React.Suspense fallback={<span>Loading...</span>}>
      <Switch>
        {(userRole !== USER_ROLES.superAdmin && userRole !== USER_ROLES.admin) && (
          <Route path={ROUTES.endUser.root} component={EndUserModule} />
        )}
        {(userRole === USER_ROLES.superAdmin || userRole === USER_ROLES.admin) && (
          <Route path={ROUTES.admin.root} component={AdminModule} />
        )}
      </Switch>
    </React.Suspense>
  </React.Fragment>
)

export default CoreLayout
