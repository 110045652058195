import { apiService } from './Api'

export default class UserPortalApi {
  getAvatars () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/user/avatars',
      headers: {},
      method: 'GET',
    })
  }

  setAvatar ({ id }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/user/avatar/${id}`,
      headers: {},
      method: 'PUT',
    })
  }

  getUserPreferences () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/user/preferences',
      headers: {},
      method: 'GET',
    })
  }

  getDebriefModules () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/debrief/modules',
      headers: {},
      method: 'GET',
    })
  }

  getDebriefModuleNavigation ({ moduleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/module/${moduleId}`,
      headers: {},
      method: 'GET',
    })
  }

  completeDebriefModuleSection ({ type, itemId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/${type}/${itemId}/complete`,
      headers: {},
      method: 'PUT',
    })
  }

  getModuleIntro ({ moduleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/module/${moduleId}/intro`,
      headers: {},
      method: 'GET',
    })
  }

  getScaleInfoBlocks ({ scaleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/scale/${scaleId}/info-blocks`,
      headers: {},
      method: 'GET',
    })
  }

  getUserScores () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/user/scores`,
      headers: {},
      method: 'GET',
    })
  }

  downloadReport ({ moduleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/module/${moduleId}/report`,
      headers: {},
      method: 'GET',
    }, {
      getBlob: true,
    })
  }

  downloadHighlights (moduleId) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/module/${moduleId}/report`,
      headers: {},
      method: 'GET',
    }, {
      getBlob: true,
    })
  }

  downloadDataReport () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/debrief/data-report',
      headers: {},
      method: 'GET',
    }, {
      getBlob: true,
    })
  }

  getIntroduction (type) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/user/introduction/${type}`,
      headers: {},
      method: 'GET',
    })
  }

  setIsIntroPassed (type) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/user/introduction/${type}`,
      headers: {},
      method: 'PUT',
    })
  }

  getForms (scaleId) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/debrief/scale/${scaleId}/action-planning-form`,
      headers: {},
      method: 'GET',
    })
  }
}
