import queryString from 'query-string'

const queryToStringParams = (query = {}) => queryString.stringify(query)

const getPath = ({ pathname = '', query, hostname }) => {
  const resPath = `${hostname}${pathname[0] === '/' ? '' : '/'}${pathname}`

  return query
    ? `${resPath}?${queryToStringParams(query)}`
    : resPath
}

export const getApiUrl = params => getPath(params)

export const handleError = (response) => {
  const { status = 500, statusText, body } = response
  const error = new Error(body || statusText)
  error.status = status
  error.statusText = statusText
  error.result = body
  throw error
}

export const fetchBlob = (requestUrl, requestOptions) => {
  return fetch(requestUrl, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.blob()
      }

      const { status, statusText, ok } = response
      const bodyPromise = response.json()
      return bodyPromise.then(body => ({ status, statusText, ok, body }))
    })
    .then(blob => {
      if (blob.ok !== undefined && !blob.ok) {
        return handleError(blob)
      }

      return blob
    })
}

const formValuesToFormObjectCreator = EntityConstructor => formValues => {
  const data = new EntityConstructor()

  Object.entries(formValues).forEach(([key, value]) => {
    data.append(key, value)
  })
  return data
}

export const getURLSearchParams = formValuesToFormObjectCreator(URLSearchParams)

export const getFormData = formValuesToFormObjectCreator(FormData)
