import { apiService } from './Api'
import { getFormData } from './utils'

export default class AdminPortalApi {
  getModuleScriptsNavigation () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/navigation',
      headers: {},
      method: 'GET',
    }, {})
  }

  getModuleByModuleId ({ moduleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/modules/${moduleId}`,
      headers: {},
      method: 'GET',
    }, {})
  }

  updateModuleByModuleId ({ moduleId }, data) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/modules/${moduleId}`,
      headers: {},
      method: 'PUT',
      data,
    }, {})
  }

  getScaleByScaleId ({ scaleId }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/scales/${scaleId}`,
      headers: {},
      method: 'GET',
    }, {})
  }

  updateScaleByScaleId ({ scaleId }, data) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/scales/${scaleId}`,
      headers: {},
      method: 'PUT',
      data,
    }, {})
  }

  uploadMedia (file) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/media',
      headers: {},
      method: 'POST',
      data: getFormData({
        media: file,
      }),
    }, {
      sendFormData: true,
    })
  }

  getAvatars () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/avatars',
      headers: {},
      method: 'GET',
    })
  }

  syncAvatars () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/avatars/sync',
      headers: {},
      method: 'POST',
    })
  }

  setAvatarStatusByAvatarId ({ avatarId, status }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/avatars/${avatarId}/status/${status}`,
      headers: {},
      method: 'PUT',
    })
  }

  postUsers ({ size, index, sort, filters }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/users',
      headers: {},
      method: 'POST',
      data: {
        size,
        index,
        sort,
        filters,
      },
    })
  }

  exportUsers ({ sort, filters }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/users/csv',
      headers: {},
      method: 'POST',
      data: {
        sort,
        filters,
      },
    }, {
      getBlob: true,
    })
  }

  getVoices () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: '/avatars/voices',
      headers: {},
      method: 'GET',
    })
  }

  setAvatarVoice (avatarId, voiceId) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/avatars/${avatarId}/voice/${voiceId}`,
      headers: {},
      method: 'PUT',
    })
  }

  getIntro (type) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/intro/${type}`,
      headers: {},
      method: 'GET',
    })
  }

  getTooltip () {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/texts/dataReportTooltip`,
      headers: {},
      method: 'GET',
    })
  }

  updateIntro (type, data) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/intro/${type}`,
      headers: {},
      method: 'PUT',
      data,
    }, {})
  }

  updateTooltip (data) {
    return apiService.apiRequest({
      hostname: apiService.hosts.appService,
      url: `/texts/dataReportTooltip`,
      headers: {},
      method: 'PUT',
      data,
    }, {})
  }
}
