import _get from 'lodash/get'

export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state
  }
}

const createModuleStateSaver = (moduleId, key, getSavedState) => {
  const getAllModuleState = () => JSON.parse(localStorage.getItem(moduleId))
  const getReducerState = () => _get(getAllModuleState(), key)
  return {
    saveState: state => {
      const newState = key
        ? {
          ...getAllModuleState(),
          [key]: getSavedState(state),
        }
        : state
      localStorage.setItem(moduleId, JSON.stringify(newState))
    },
    getInitialState: (initialState) => {
      if (key) {
        return {
          ...initialState,
          ...getReducerState(),
        }
      }
      return {
        ...initialState,
        ...getAllModuleState(),
      }
    },
  }
}

export const createModuleReducer = (initialState, handlers, globalHandlers = {}, getSavedState = state => state) =>
  (moduleId, key) => {
    const moduleHandlers = Object.entries(handlers).reduce((acc, [type, handler]) => ({
      ...acc,
      [`${moduleId}/${type}`]: handler,
    }), {})
    const moduleSaver = createModuleStateSaver(moduleId, key, getSavedState)
    const moduleInitialState = moduleSaver.getInitialState(initialState) || initialState

    const allHandlers = {
      ...moduleHandlers,
      ...globalHandlers,
    }

    return (state = moduleInitialState, action) => {
      if (allHandlers.hasOwnProperty(action.type)) {
        const newState = allHandlers[action.type](state, action)
        moduleSaver.saveState(newState)
        return newState
      }
      return state
    }
  }

const defaultActionCreator = type => (payload, meta) => ({ type, payload, meta })

export const createModuleAction = module => (name, actionCreator = null) => {
  const finalName = `${module}/${name}`
  let currentActionCreator = actionCreator
  if (!currentActionCreator) {
    currentActionCreator = defaultActionCreator(finalName)
  }
  const result = (...params) => {
    const action = currentActionCreator(...params)
    action.type = finalName
    return action
  }
  result.toString = () => finalName
  return result
}
