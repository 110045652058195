import { apiService } from './Api'
import { getFormData } from './utils'

export default class AuthApi {
  login ({ username, password }) {
    return apiService.apiRequest({
      hostname: apiService.hosts.auth,
      url: '/connect/token',
      data: getFormData({
        client_id: 'cdru.web.admin',
        // client_secret: 'secret',
        grant_type: 'password',
        scope: 'cdru.api.admin offline_access',
        username,
        password,
      }),
      headers: {},
      method: 'POST',
    }, {
      noAuth: true,
      sendFormData: true,
    })
  }
}
