export const ERROR_CODES = {
  common: 0,
  notNull: 2,
  notEmpty: 3,
  notFound: 100,
  fileFormatNotSupported: 101,
  fileTooBig: 102,
  videoFormatNotSupported: 103,
  videoTooBig: 104,
  duplicatedName: 105,
  documentFormatNotSupported: 106,
  documentTooBig: 107,
  // sitepal codes
  sitepalAuthenticationFailed: 200,
  sitepalUnauthorizedIP: 201,
  sitepalNoSuchAccount: 202,
  sitepalInvalidParameter: 203,
  sitepalRequiredParameterIsMissing: 204,
  noSuchAccount: 205,
  invalidParameter: 206,
  requiredParameterIsMissing: 207,
  noSuchScene: 208,
  sitepalUnavailable: 299,
  // auth errors
  invalidCredentials: 303,
  accessFailedCount: 304,
  // admin errors
  cannotEnableWithoutEmbedId: 400,
  cannotEnableWithoutVoiceId: 401,
  previousScalesNotCompleted: 500,
  previousModuleNotCompleted: 501,
  // client errors
  emptyFields: 'emptyFields',
  errorUploadFile: 'errorUploadFile',
  errorLoadFile: 'errorLoadFile',
}

export const MESSAGE_BY_ERROR_CODE = {
  [ERROR_CODES.common]: 'Something went wrong.',
  [ERROR_CODES.notNull]: 'This field "{{name}}" is required.',
  [ERROR_CODES.notEmpty]: 'This field "{{name}}" is required.',
  [ERROR_CODES.notFound]: 'The object could not be found',
  [ERROR_CODES.fileFormatNotSupported]: 'The file format is not supported. Supported formats: JPG, PNG, MOV, MP4',
  [ERROR_CODES.fileTooBig]: 'The file is too big. Max allowed size: Image - 20Mb. Video - 300Mb.',
  [ERROR_CODES.videoFormatNotSupported]: 'The file format is not supported. Supported format: MOV, MP4.',
  [ERROR_CODES.videoTooBig]: 'The file is too big. Max allowed size is 300 Mb.',
  [ERROR_CODES.documentFormatNotSupported]: 'The file format is not supported. Supported formats: PDF, DOCX',
  [ERROR_CODES.documentTooBig]: 'The file is too big. Max allowed size is 2 Mb.',
  [ERROR_CODES.duplicatedName]: 'The name already exists in the system.',
  [ERROR_CODES.sitepalAuthenticationFailed]: 'SitePal server unavailable.',
  [ERROR_CODES.sitepalUnauthorizedIP]: 'SitePal server unavailable.',
  [ERROR_CODES.sitepalNoSuchAccount]: 'SitePal server unavailable.',
  [ERROR_CODES.sitepalInvalidParameter]: 'SitePal server unavailable.',
  [ERROR_CODES.sitepalRequiredParameterIsMissing]: 'SitePal server unavailable.',
  [ERROR_CODES.noSuchAccount]: 'SitePal server unavailable.',
  [ERROR_CODES.invalidParameter]: 'SitePal server unavailable.',
  [ERROR_CODES.requiredParameterIsMissing]: 'SitePal server unavailable.',
  [ERROR_CODES.noSuchScene]: 'SitePal server unavailable.',
  [ERROR_CODES.sitepalUnavailable]: 'SitePal server unavailable.',
  [ERROR_CODES.errorUploadFile]: 'Something went wrong. Please try to upload the file again.',
  [ERROR_CODES.invalidCredentials]: 'Сredentials could not be validated.',
  [ERROR_CODES.accessFailedCount]: 'Сredentials could not be validated. Please contact us at support@cdrassessmentgroup.com',
  [ERROR_CODES.cannotEnableWithoutEmbedId]: 'Try to synchronize Avatar first.',
  [ERROR_CODES.cannotEnableWithoutVoiceId]: "Try to choose an Avatar's voice first.",
  [ERROR_CODES.previousScalesNotCompleted]: 'Please complete the previous Module/Scale/Subscale.',
  [ERROR_CODES.previousModuleNotCompleted]: 'Please complete the previous Module/Scale/Subscale.',
  [ERROR_CODES.emptyFields]: 'Please complete required fields',
  [ERROR_CODES.errorLoadFile]: 'Unable to open file.',
}
