import _get from 'lodash/get'
import { createReducer } from 'utils/reducer'
import { tokenService } from 'services/Token'
import { SET_USER, CLEAN_USER } from './authActions'

const initialState = {
  role: tokenService.getUserRole(),
  firstName: _get(tokenService.getTokenPayload(tokenService.getAccessToken()), 'first_name', ''),
  lastName: _get(tokenService.getTokenPayload(tokenService.getAccessToken()), 'last_name', ''),
  companyName: _get(tokenService.getTokenPayload(tokenService.getAccessToken()), 'company_name', ''),
}

const handlers = {
  [SET_USER]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [CLEAN_USER]: state => ({
    ...state,
    role: null,
    firstName: '',
    lastName: '',
    companyName: '',
  }),
}

export default createReducer(initialState, handlers)
